'use client'

import { ORIGIN, STATION_ID_COOKIE } from "@/lib/constants"
import { ProfileData } from "@/lib/types/api/profile-data"
import Cookies from "js-cookie"
import { fetchViewingHistory } from "@/lib/content-services/queries"
import { USER_ID_COOKIE } from '@/lib/constants';
import safeFetch from "@/lib/helpers/safe-fetch"

export const NoUIDError = new Error('PBS UID Cookie is not set')

export function getStationId() {
  return Cookies.get(STATION_ID_COOKIE) || window.SVP?.stationId
}

export function getUserId() {
  return Cookies.get(USER_ID_COOKIE)
}

export async function getProfile(): Promise<ProfileData> {
  const response = await safeFetch(`${ORIGIN}/sso/profile`, {
    credentials: 'include'
  })

  if(response.status === 403 || response.status === 404) {
    const domain = window.pbsPlatform === 'svp' ? window.location.hostname : '.pbs.org'
    Cookies.remove(USER_ID_COOKIE, { path: '/', domain })
    throw new Error('invalid session')
  }

  const json = await response.json()

  // Override profile service passport flag with data directly from mvault
  const { passportEnabled } = await getPassportStatus()
  json.personal_data.is_passport = passportEnabled

  return json
}

export async function getPassportStatus() {
  const response = await safeFetch(`/api/v1/membership/${getUserId()}/passport/`)
  return await response.json()
}

export async function getFavoriteStations() {
  const pid = Cookies.get(USER_ID_COOKIE)

  if(!pid) {
    throw NoUIDError
  }

  const response = await safeFetch('/api/profile/stations/')
  return await response.json()
}

export async function getViewingHistory() {
  const pid = Cookies.get(USER_ID_COOKIE)

  if(!pid) {
    throw NoUIDError
  }

  const viewingHistory = await fetchViewingHistory(pid)
  return viewingHistory
}

export async function setFavoriteStation(stationId: string) {
  const pid = Cookies.get(USER_ID_COOKIE)

  if(!pid) {
    throw NoUIDError
  }

  const response = await safeFetch('/api/profile/stations/', {
    method: 'PUT',
    body: JSON.stringify({ stationId })
  })
  return await response.json()
}

export async function putCampaignInfo(uid: string, campaignInfoCode: string) {
  const campaignInfoParams = new URLSearchParams();
  campaignInfoParams.append('campaignInfoCode', campaignInfoCode);

  const response = await safeFetch(
    `/api/profile/campaign-info/${uid}/?${campaignInfoParams.toString()}`, 
    { method: 'PUT' }
  )

  return response.ok
}
